<template>
    <div>
        <ViewLoadingMessage :loading="loading"/>
        <template v-if="! loading">
            <CCard>
                <CCardBody class="p-0">
                    <CListGroup v-if="activities" flush class="activity-list">
                        <CListGroupItem v-for="(activity, key) in activities"
                                        class="flex-column align-items-start"
                                        :key="key">
                            <div class="d-flex w-100 justify-content-between">
                                <small>{{ activity.created_at|formatDateTime }}</small>
                            </div>
                            <p class="mb-1">
                                {{ activity.description }}
                            </p>
                        </CListGroupItem>
                    </CListGroup>

                    <div v-else class="text-warning">
                        <translate>No activity logged</translate>
                    </div>
                </CCardBody>
            </CCard>
        </template>
    </div>
</template>


<script>
import ActivityService from "../services/activity.service"
import ViewLoadingMessage from "@/components/ViewLoadingMessage"

export default {
    name: 'ActivityTimeline',

    components: {
        ViewLoadingMessage
    },

    props: {
        latest: {
            type: String,
            default: undefined
        },
    },

    data() {
        return {
            loading: false,
            activities: null
        }
    },

    mounted() {
        this.loading = true
        ActivityService.getActivities(this.$store.getters['event/picaServiceId'])
            .then((response) => {
                if (this.latest)
                    this.activities = response.data.reverse().slice(0, this.latest)
                else
                    this.activities = response.data

                this.loading = false
            })
    }
}
</script>