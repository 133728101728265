<template>
    <div>
        <h1 class="main-header" v-translate>Activities</h1>
        <ActivityTimeline/>
    </div>
</template>

<script>
    import ActivityTimeline from "../../components/ActivityTimeline"

    export default {
        name: "ActivityList",
        components: {ActivityTimeline},
    }
</script>
