import client_core from "@/services/clients/client_core";

const ActivityService = {
    getActivities(picaServiceId) {
        let params = {
            'pica_service': picaServiceId
        }
        return client_core.get('/log/v1/activities/', { params })
    },
}

export default ActivityService